<template lang="">
  <flat-pickr
    id="specifiedPassengers"
    :value="dob"
    :name="$t('flight.dob')"
    class="form-control px-50 py-0"
    :config="{
      ...configFlatPickr,
      altInputClass: 'form-control input px-50 py-0',
    }"
    :style="{height: '30px'}"
    :placeholder="$t('reservation.specifiedPassengersPlaceholder')"
    @input="val => handleInput(val)"
  />
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import { ref, computed } from '@vue/composition-api'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import i18n from '@/libs/i18n'
import YearDropdownPlugin from '@/libs/flatpickr/yearDropdownPlugin'

// NOTE: Lỗi flatpickr trong modal ko input được năm => thêm code này để fix
// Thanks to https://github.com/flatpickr/flatpickr/issues/2576#issuecomment-1004651200
document.addEventListener('focusin', e => {
  if (e.target.closest('.flatpickr-calendar')) {
    e.stopImmediatePropagation()
  }
})
export default {
  components: {
    flatPickr,
  },
  props: {
    dob: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const locale = computed(() => i18n.locale).value
    const configFlatPickr = ref(
      {
        plugins: [
          new YearDropdownPlugin({
            date: new Date(),
            yearStart: 100,
            yearEnd: 0,
          }),
        ],
        dateFormat: 'Y-m-d',
        minDate: '1.1.1920',
        maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )
    function handleInput(val) {
      if (!val) val = null
      emit('update:dob', val)
    }
    return {
      configFlatPickr,
      handleInput,
    }
  },
}
</script>
<style lang="">

</style>
