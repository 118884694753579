var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('flat-pickr', {
    staticClass: "form-control px-50 py-0",
    style: {
      height: '30px'
    },
    attrs: {
      "id": "specifiedPassengers",
      "value": _vm.dob,
      "name": _vm.$t('flight.dob'),
      "config": Object.assign({}, _vm.configFlatPickr, {
        altInputClass: 'form-control input px-50 py-0'
      }),
      "placeholder": _vm.$t('reservation.specifiedPassengersPlaceholder')
    },
    on: {
      "input": function input(val) {
        return _vm.handleInput(val);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }